@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Motiva Sans Light', sans-serif;
}
label{
  display: block;
  margin-bottom: 2px;
  font-family: 'Courier New', Courier, monospace;
}
.testPad {
  padding-left: 0.5rem;
}
.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(./images/banner2.jpg) fixed center center;
}


.capdetails {
 background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)), url(./images/concept1.jpeg) fixed center bottom;
}

.contactheader{
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)), url(./images/conceptBanner.jpg) fixed center top;
 }

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: #21aeca; */
}

.accordion-title:hover {
   /*background-color: rgb(35, 81, 233) */
}

.accordion-title{
  color: rgb(30 58 138 / var(--tw-text-opacity));
  font-size: 1.5rem;
  padding: 1rem;
  
}
.accordion-content {
  padding: 1rem;
  
}

.accordion-content {
  /* background-color: #39b9d2; */
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
  font-style: italic;
}